// @ts-ignore
import PaneelNavigatie from "../../../components/Paneel/paneelNavigatie";
import { useState, useEffect } from "react";
import { _ } from "../../../modules/functions";
import React from "react";
import { Helmet } from "react-helmet";
import { Page, Text, Document, StyleSheet } from "@react-pdf/renderer";

import Loading from "../../../components/Loading";
import Modal from "../../../components/modal";
import Paneel_FkStudent from "./Lid";
import { Switch } from "@headlessui/react";
import { check_login, check_permission } from "../../../auth/auth";
import axios from "axios";
import config from "../../../config";
import useDebounce from "../../../hooks/useDebounce";
// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: "row",
        backgroundColor: "#fff"
    },
    text: {
        position: "absolute",
        fontSize: "0.28cm",
        bottom: "1.08cm",
        left: "3.5cm",
        width: "4.3cm"
    }
});

interface DocumentProps {
    name: string;
}
// Create Document Component
const MyDocument = ({ name }: DocumentProps) => (
    <Document>
        <Page size={[54 * 2.8566666666666, 85 * 2.834646464646]} orientation="landscape" style={styles.page}>
            <Text style={styles.text}>{name}</Text>
        </Page>
    </Document>
);
export interface Card {
    id: number;
    academic_year: string;
    number: number;
    status: string;
    enabled: boolean;
    created_at: Date;
    updated_at: Date;
    isic_status: string;
    isic_number?: any;
    isic_exported: boolean;
    card_type: string;
    citylife_exported: boolean;
}

export interface FK_Student {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    ugent_nr: string;
    sex?: any;
    phone: string;
    date_of_birth: string;
    home_address?: any;
    studenthome_address?: any;
    created_at: Date;
    updated_at: Date;
    isic_newsletter?: any;
    isic_mail_card?: any;
    last_registration: number;
    home_street: string;
    home_postal_code: string;
    home_city: string;
    studenthome_street: string;
    studenthome_postal_code: string;
    studenthome_city: string;
    card_type_preference?: any;
    state: string;
    card: Card;
}
export default function Paneel_Lidkaarten() {
    const [claims] = useState(check_login(true));
    const [allowed] = useState(check_permission("praesidium", true));
    const [naamFilter, setNaamFilter] = useState("");
    const [betaaldFilter, setBetaaldFilter] = useState(true);
    const [loading, setLoading] = useState(true);
    const [gefilterdeStudenten, setGefilterdeStudenten] = useState<FK_Student[]>([]);
    const [studenten, setStudenten] = useState<FK_Student[]>([]);
    const [studentModal, setStudentModal] = useState<string[]>([]);
    useEffect(() => {
        Load();
    }, []);
    function Load() {
        setLoading(true);
        setStudentModal([]);

        axios.get(config.api_url + "/lidkaarten/").then((response) => {
            let data: FK_Student[] = response.data;
            setStudenten(data);
            setLoading(false);
        });
    }

    useDebounce(
        () => {
            let filtered: FK_Student[] = studenten;
            filtered = filtered.filter((student) => student.card.academic_year === "2024-2025");
            filtered = filtered.filter((student) => _.STRING_CONTAINS(student.first_name + student.last_name, naamFilter));

            if (betaaldFilter) filtered = filtered.filter((student) => student.card.status === "paid");
            else filtered = filtered.filter((student) => student.card.status === "unpaid");
            // filters op toepassen
            filtered = filtered.sort((a, b) => new Date(b.card.updated_at).getTime() - new Date(a.card.updated_at).getTime());
            setGefilterdeStudenten(filtered);
            setLoading(false);
        },
        500,
        [studenten, naamFilter, betaaldFilter]
    );

    useEffect(() => {
        setLoading(true);
    }, [studenten, naamFilter, betaaldFilter]);

    return (
        <>
            {claims && allowed && (
                <>
                    <PaneelNavigatie />
                    <Helmet>
                        <title>Paneel - Lidkaarten - Hermes Gent</title>
                    </Helmet>
                    <div className="lg:w-8/12 w-11/12 pt-20 pb-40 mx-auto">
                        <div className="flex items-center gap-4">
                            <h1 className="font-bold text-4xl uppercase text-zinc-800  dark:text-zinc-300 flex-grow">Lidkaarten</h1>
                            <button onClick={() => Load()}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg>
                            </button>
                        </div>
                        <div>Paid cards: {studenten.filter((student) => student.card.status === "paid").length + " / " + studenten.length}</div>
                        <div className="text-zinc-800 dark:text-zinc-300 mt-8 shadow p-4 lg:p-8 rounded-lg dark:bg-black/70">
                            <label htmlFor="naam" className="text-lg font-light">
                                Naam van student
                            </label>
                            <input
                                value={naamFilter}
                                onChange={(e) => setNaamFilter(e.target.value)}
                                type="text"
                                id="naam"
                                placeholder="Naam van student"
                                className="mt-2 mb-4 w-full border border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 bg-transparent dark:border-zinc-700 px-3 py-2 dark:text-zinc-400 rounded-md"
                            />
                            <div className="mt-6 flex items-center">
                                <label className="text-lg font-light text-zinc-600 dark:text-zinc-500 mr-2">Reeds betaald: </label>

                                <Switch checked={betaaldFilter} onChange={setBetaaldFilter} className={`${betaaldFilter ? "bg-blue-600" : "bg-gray-200 dark:bg-zinc-700"} relative inline-flex items-center h-6 rounded-full w-11`}>
                                    <span className={`${betaaldFilter ? "translate-x-6" : "translate-x-1"} transition-transform inline-block w-4 h-4 transform bg-white rounded-full`} />
                                </Switch>
                            </div>
                            <div className="h-96 overflow-y-scroll px-2">
                                {!loading &&
                                    gefilterdeStudenten?.map((student) => {
                                        return (
                                            <div className="mt-2 shadow py-4 px-4 lg:px-6 rounded-md dark:bg-zinc-900 flex gap-4 items-center">
                                                <div className="w-7 h-7 rounded-md bg-blue-200 text-blue-700 font-bold dark:bg-blue-600 dark:text-zinc-200 flex items-center justify-center">{student.first_name.toUpperCase().slice(0, 1)}</div>
                                                <div className="flex-grow">{student.first_name + " " + student.last_name}</div>
                                                <div>
                                                    <svg className={`h-6 w-6 ${student.card.status === "paid" ? "text-emerald-500" : "text-red-500"}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"></path>
                                                    </svg>
                                                </div>
                                                <Modal
                                                    condition={studentModal}
                                                    setCondition={setStudentModal}
                                                    uuid={student.id}
                                                    boxClasses="w-full md:w-8/12 xl:w-5/12 md:mx-auto mt-12 lg:mt-36 mb-16"
                                                    openButtonClasses="px-4 py-2 text-sm bg-blue-700 text-white rounded-md font-bold"
                                                    openButtonValue={"OPEN"}>
                                                    <Paneel_FkStudent reload={() => Load()} student={student} />
                                                </Modal>
                                            </div>
                                        );
                                    })}
                                {loading && (
                                    <div className="text-zinc-500 flex items-center mt-4 gap-2">
                                        <Loading size={5} />
                                        Studenten inladen...
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
